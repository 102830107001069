import request from '@/utils/request'
// 优惠券列表
const activitycouponCouponlist = (data) => request('/admin/card/cardList', data);
// 优惠券删除
const activitycouponActivitycoupondelete = (data) => request('/admin/card/delCoupon', data);
// 获取优惠券详情
const activitycouponActivitycoupondetail = (data) => request('/admin/card/findById', data);
// 添加优惠券
const activitycouponActivitycouponedit = (data) => request('/admin/card/addOrUpCard', data);
// 编辑优惠券
const activitycouponActivitycouponupdate = (data) => request('/admin/card/updateCard', data);
// 优惠券设置失效
const activitycouponActivitycouponsetisopen = (data) => request('/admin/card/updateCardType', data);
// 优惠券数据统计
const activitycouponActivitycouponstatistical = (data) => request('/pc/activitycoupon/activitycouponstatistical', data);
// 获取弹窗产品列表
const activitycouponChoseproductlist = (data) => request('/admin/goods/getPagedListForAct', data);

// 获取弹窗产品列表 微页面使用商品列表
const activityproductmicrolist = (data) => request('/admin/goods/goodsList/micro', data);

// 满减活动-添加/编辑满减送活动
const activityMarkdownGiftAdd = (data) => request('/admin/activityFull/addActivityFull', data);
// 满减活动-选择优惠卷弹框
const activityMarkdownGiftChoseCoupon = (data) => request('/pc/ActivityMarkdownGift/ChoseCoupon', data);
// 满减活动-选择商品弹框
const activityMarkdownGiftChoseProduct = (data) => request('/pc/ActivityMarkdownGift/ChoseProduct', data);
// 满减送活动-点击删除
const activityMarkdownGiftDel = (data) => request('/admin/activityFull/delActivityFull', data);
// 满减送活动-设置循环满减
const activityMarkdownGiftIsLoop = (data) => request('/pc/ActivityMarkdownGift/IsLoop', data);
// 满减送活动-点击终止
const activityMarkdownGiftIsOpen = (data) => request('/pc/ActivityMarkdownGift/IsOpen', data);
// 满减送活动-满减送列表
const activityMarkdownGiftList = (data) => request('/admin/activityFull/findAll', data);
//  满减送活动-获取满减送详情
const activityMarkdownGiftModel = (data) => request('/admin/activityFull/findByFull', data);
//  满减送活动-验证分销礼包商品
const verifyAuthGift = (data) => request('/pc/ActivityMarkdownGift/verifyAuthGift', data);

// 抽奖活动-删除
const activityWheelSurfDel = (data) => request('/pc/activityWheelSurf/delete', data);
// 抽奖活动-详情
const activityWheelSurfInfo = (data) => request('/pc/activityWheelSurf/info', data);
// 抽奖活动-详情
const activityWheelSurfDetail = (data) => request('/pc/activityWheelSurf/detail', data);
// 抽奖活动-列表
const activityWheelSurfList = (data) => request('/pc/activityWheelSurf/list', data);
// 抽奖活动-使终止
const activityWheelSurfOpen = (data) => request('/pc/activityWheelSurf/open', data);
//  抽奖活动-商品列表
const activityWheelSurfProductList = (data) => request('/pc/activityWheelSurf/productList', data);
//  抽奖活动-保存
const activityWheelSurfSave = (data) => request('/pc/activityWheelSurf/save', data);
//  抽奖活动-弹窗列表 
const activityWheelSurfPopupList = (data) => request('/pc/activityWheelSurf/popupList', data);

//   社群配置-初始化
const communityGroupSettingInit = (data) => request('/pc/communityGroupSetting/init', data);
//  社群配置-保存 
const communityGroupSettingSave = (data) => request('/pc/communityGroupSetting/save', data);
//   社群数据
const communityGroupData = (data) => request('/pc/communityGroup/communityGroupData', data);

// 限时折扣弹窗列表 
const activityDiscountPopupList = (data) => request('/pc/activityDiscount/popupList', data);

//商品-获取商品规格信息
const productSpecGet = (data) => request('/admin/activity/findByGoodSku', data);

// 订单返现失效
const activitycashbackClose = (data) => request('/pc/activity-cashback/close', data);
// 订单返现删除
const activitycashbackDelete = (data) => request('/pc/activity-cashback/delete', data);
// 订单返现编辑初始化
const activitycashbackEditInit = (data) => request('/pc/activity-cashback/edit-init', data);
//  订单返现编辑保存
const activitycashbackEditsave = (data) => request('/pc/activity-cashback/edit-save', data);
//  订单返现活动列表
const activitycashbackIndex = (data) => request('/pc/activity-cashback/index', data);

// 优惠券弹窗列表 cxd
const activitycouponpopupList = (data) => request('/pc/activitycoupon/popupList', data);

// 积分商城-删除
const activityPointExchangeDelete = (data) => request('/pc/activityPointExchange/delete', data);
// 积分商城-详情
const activityPointExchangeInfo = (data) => request('/pc/activityPointExchange/info', data);
//  积分商城-列表
const activityPointExchangeList = (data) => request('/pc/activityPointExchange/list', data);
//  积分商城-使终止
const activityPointExchangeOpen = (data) => request('/pc/activityPointExchange/open', data);
//  积分商城-商品列表
const activityPointExchangeProductList = (data) => request('/pc/activityPointExchange/productList', data);
//  积分商城-保存
const activityPointExchangeSave = (data) => request('/pc/activityPointExchange/save', data);

//  优惠券弹窗列表
const activitycouponPopupList = (data) => request('/pc/activitycoupon/popupList', data);

//  签到统计
const memberSignRecordList = (data) => request('/pc/MemberSignRecord/List', data);
//  签到设置-编辑
const signConfigEdit = (data) => request('/pc/SignConfig/Edit', data);
//  签到设置-详情
const signConfigModel = (data) => request('/pc/SignConfig/Model', data);

// 退款/售后列表
const ordersupportIndex = (data) => request('/admin/afterSale/refund/return/list', data);
// 退款/售后列表统计
const afterSaleCount = (data) => request('/admin/afterSale/refund/refund/count', data);
// 换货列表
const ordersupportExchangeIndex = (data) => request('/admin/afterSale/exchange/list', data);
// 换货统计
const afterSaleExchangeCount = (data) => request('/admin/afterSale/exchange/count', data);
// 退款单列表
const afterSaleAllRefundList = (data) => request('/admin/afterSale/refund/list', data);
// 售后订单列表
const afterSaleList = (data) => request('/admin/afterSale/list', data);
// 换货统计
const afterSaleCount2 = (data) => request('/admin/afterSale/count', data);

//售后导出
//退款单列表导出
const afterSaleAllRefundListExport = (data) => request('/admin/afterSale/refund/export', data);
// 换货列表导出
const ordersupportExchangeIndexExport = (data) => request('/admin/afterSale/exchange/list/export', data);
//售后订单列表导出
const afterSaleListExport = (data) => request('/admin/afterSale/export', data);
//售后退款列表导出
const ordersupportIndexExport = (data) => request('/admin/afterSale/refund/return/export', data);


// 退款/售后列表导出
const ordersupportExport = (data) => request('/pc/order-support/index-export', data);
// 退款/售后列表初始化
const ordersupportInit = (data) => request('/pc/order-support/init', data);
//  订单退款详情
const ordersupportDetail = (data) => request('/admin/afterSale/detail', data);
//  售后单查看物流
const ordersupportExpress = (data) => request('/pc/order-support/look-express', data);
//  订单退款同意申请
const ordersupportAgreeapply = (data) => request('/pc/order-support/agree-apply', data);
//  订单退款同意退款
const ordersupportAgreerefund = (data) => request('/pc/order-support/agree-refund', data);
//  售后申请审核
const ordersupportRefuseapply = (data) => request('/admin/afterSale/approval', data);
//  订单退款不同意退款
const ordersupportRefuserefund = (data) => request('/pc/order-support/refuse-refund', data);
//  订单退款关闭申请
const ordersupportCloseApply = (data) => request('/pc/order-support/close-apply', data);
//  确认退换货
const deliverysConfirm = (data) => request('/admin/afterSale/refundOrExchange', data);
//  收货地址列表
const deliveryAddressList = (data) => request('/admin/afterSale/address/list', data);
//  确认收货
const deliveryShouhuo = (data) => request('/admin/afterSale/delivery/confirm', data);



//  订单导出
const orderExport = (data) => request('/pc/order/export', data);
//  订单导出记录 
const orderExportRecordIndex = (data) => request('/pc/order-export-record/index', data);


// 分销供货单初始化
const supplierOrderInit = (data) => request('/pc/supplier-order-support/init', data);
// 分销供货单列表
const supplierOrderIndex = (data) => request('/pc/supplier-order-support/index', data);
//  供货商订单详情
const supplierOrderDetail = (data) => request('/pc/supplier-order-support/look-detail', data);
//  供货商售后单查看物流 
const supplierOrderExpress = (data) => request('/pc/supplier-order-support/look-express', data);

//  供货商订单退款同意退款
const supplierOrderAgreeRefund = (data) => request('/pc/supplier-order-support/agree-refund', data);
//  供货商订单退款同意退款
const supplierOrderAgreeApply = (data) => request('/pc/supplier-order-support/agree-apply', data);
//  供货商订单退款否决退款申请
const supplierOrderRefuseApply = (data) => request('/pc/supplier-order-support/refuse-apply', data);
//  供货商订单退款同意退款
const supplierOrderRefuseRefund = (data) => request('/pc/supplier-order-support/refuse-refund', data);
//  主页导航
const homeNavigationIndex = (data) => request('/pc/home-navigation/index', data);
//  主页导航保存
const homeNavigationSave = (data) => request('/pc/home-navigation/save', data);
//  页面管理编辑选择商品
const mallShopDecorateSelect = (data) => request('/pc/mall-shop-decorate/index', data);
//  页面管理编辑通过分组选择商品
const mallShopDecorateSelectGroup = (data) => request('/pc/mall-shop-decorate/edit-select-product-by-group', data);

//  评价列表批量审核（审核也用这个）
const commentBatchCheck = (data) => request('/admin/order/review/approve/batch', data);
//  评价列表删除（删除评论同时会删除追评）
const commentDelete = (data) => request('/admin/order/review/del', data);
//  评价列表
const commentList = (data) => request('/admin/order/review/list', data);
//  评价列表商家回复
const commentMallReply = (data) => request('/pc/product-comment/mall-reply', data);
//  签到设置-是否开启配置
const SignConfigIsOpen = (data) => request('/pc/SignConfig/IsOpen', data);
//  签到设置-开启状态初始化
const SignConfigIsOpenState = (data) => request('/pc/SignConfig/IsOpenState', data);

//  数据统计-流量概览SZT
const dataStatisFlowOverview = (data) => request('/pc/dataStatis/flowOverview', data);
//  数据统计-流量来源（前端分页）
const dataStatisFlowReferer = (data) => request('/pc/dataStatis/flowReferer', data);
//  数据统计-流量来源趋势（返回30天数据）
const dataStatisFlowRefererTrend = (data) => request('/pc/dataStatis/flowRefererTrend', data);
//  数据统计-流量趋势SZT
const dataStatisFlowTrend = (data) => request('/pc/dataStatis/flowTrend', data);
//  访客分析--访客地域分析
const visitorVisitorAreaAnalyze = (data) => request('/pc/dataStatis/visitor/visitorAreaAnalyze', data);
//  访客分析-访客深度
const visitorVisitorDepth = (data) => request('/pc/dataStatis/visitor/visitorDepth', data);
//  页面分析
const dataStatisPageAnalyze = (data) => request('/pc/dataStatisPageAnalyze/index', data);
//  客户概览
const dataStatisCustomerOverview = (data) => request('/pc/dataStatisCustomerOverview/customerOverview', data);
//  客户概览-客户留存分析
const dataStatisCustomerRetention = (data) => request('/pc/dataStatisCustomerOverview/customerRetention', data);
//  客户概览--客户成交分析、会员粉丝成交
const dataStatisTradingAnalyze = (data) => request('/pc/dataStatisCustomerOverview/tradingAnalyze', data);
//  微信分析分析-粉丝分层
const wxFansAnalyzeFanLayered = (data) => request('/pc/dataStatisWxFansAnalyze/fanLayered', data);
//  粉丝增减
const FansIncreaseOrDecrease = (data) => request('/pc/dataStatisWxFansAnalyze/fansIncreaseOrDecreaseApi', data);
//  粉丝信息
const wxFansAnalyzeFansInfo = (data) => request('/pc/dataStatisWxFansAnalyze/fansInfo', data);
//  营销-营销分析-其他活动-数据趋势
const dataStatisActivityDetail = (data) => request('/pc/dataStatisActivityAnalyze/activityDetail', data);
//  营销-营销分析-其他活动-营销概况
const dataStatisActivityGeneral = (data) => request('/pc/dataStatisActivityAnalyze/activityGeneral', data);
//  营销-营销分析-其他活动-商品效果
const dataStatisActivityProduct = (data) => request('/pc/dataStatisActivityAnalyze/activityProduct', data);
//  营销-营销分析-优惠券-数据趋势
const dataStatisCouponDetail = (data) => request('/pc/dataStatisActivityAnalyze/couponDetail', data);
//  营销-营销分析-优惠券-营销概况
const dataStatisCouponGeneral = (data) => request('/pc/dataStatisActivityAnalyze/couponGeneral', data);
//  营销-营销分析-优惠券-商品效果
const dataStatisCouponProduct = (data) => request('/pc/dataStatisActivityAnalyze/couponProduct', data);
//  营销分析，满减送
const activityMardownGiftPopupList = (data) => request('/pc/activityMardownGift/popupList', data);

//  获取小程序二维码
const mallGetQrCode = (data) => request('/admin/code/hBGCode', data);

//  我的账户-列表
const mallBalanceIndex = (data) => request('/pc/mallBalance/index', data);
//  我的账户-初始化
const mallBalanceInit = (data) => request('/pc/mallBalance/init', data);

//  销售明细-列表
const performanceOrderList = (data) => request('/pc/employeePerformance/orderList', data);
//  锁粉明细-列表
const performanceLockFansList = (data) => request('/pc/employeePerformance/lockFansList', data);

//  到货提醒-列表
const productSubscribeList = (data) => request('/pc/productSubscribe/list', data);

//  微信物流助手发货地址
const expressAssistantIndex = (data) => request('/pc/wx-express-assistant-sender/index', data);
//  微信物流助手发货地址保存
const expressAssistantSave = (data) => request('/pc/wx-express-assistant-sender/save', data);

//  微信物流助手打单记录
const assistantRecordIndex = (data) => request('/pc/wx-express-assistant-push-record/index', data);
//  微信物流助手打单记录确认发货
const assistantRecordSend = (data) => request('/pc/wx-express-assistant-push-record/send', data);
//  微信物流助手打单列表批量打单中止
const assistantRecordStop = (data) => request('/pc/wx-express-assistant-push-record/stop', data);

//  渠道推广码-删除
const promoteCodeDelete = (data) => request('/pc/promoteCode/delete', data);
//  渠道推广码-列表
const promoteCodeList = (data) => request('/pc/promoteCode/list', data);
//  渠道推广码-保存
const promoteCodeSave = (data) => request('/pc/promoteCode/save', data);
// 自定义页面-弹框列表
const mallShopDecorateBoxList = (data) => request('/pc/mallShopDecorate/filterBoxList', data);


// 供货市场
const supplierMarketProductList = (data) => request('/pc/supplierChain/supplierMarketProductList', data);
// 供货市场-添加到仓库/上架到商城
const supplierMarketProductSave = (data) => request('/pc/supplierChain/supplierMarketProductSave', data);

// 门店标签
const shopTagList = (data) => request('/pc/ShopTag/list', data);

// 订单毛利预算
const grossProfitList = (data) => request('/pc/order/grossProfit/list', data);


// 配置员工社群配置Id
const setEmployeeCommunityGroupId = (data) => request('/pc/employee/setEmployeeCommunityGroupId', data);

//用户地址列表
const userAddressList = (data) => request('/admin/afterSale/user/address/list', data);

//创建订单详情
const createorderdetail = (data) => request('/admin/afterSale/create/order/detail', data);

//创建订单
const createorder = (data) => request('/admin/afterSale/order/create', data);

//添加收货地址
const useraddressadd = (data) => request('/admin/afterSale/user/address/add', data);

//会员创建补发订单
const vipcreateorder = (data) => request('/admin/userinfo/createOrder', data);

//会员创建补发订单
const vipfindOrderNo = (data) => request('/admin/userinfo/findOrderNo', data);

export default {
    activitycouponCouponlist,
    activitycouponActivitycoupondelete,
    activitycouponActivitycoupondetail,
    activitycouponActivitycouponedit,
    activitycouponActivitycouponsetisopen,
    activitycouponActivitycouponstatistical,
    activitycouponChoseproductlist,
    activityMarkdownGiftAdd,
    activityMarkdownGiftChoseCoupon,
    activityMarkdownGiftChoseProduct,
    activityMarkdownGiftDel,
    activityMarkdownGiftIsLoop,
    activityMarkdownGiftIsOpen,
    activityMarkdownGiftList,
    activityMarkdownGiftModel,
    activitycashbackClose,
    activitycashbackDelete,
    activitycashbackEditInit,
    activitycashbackEditsave,
    activitycashbackIndex,
    activitycouponpopupList,
    activityPointExchangeDelete,
    activityPointExchangeInfo,
    activityPointExchangeList,
    activityPointExchangeOpen,
    activityPointExchangeProductList,
    activityPointExchangeSave,
    activitycouponPopupList,

    activityWheelSurfDel,
    activityWheelSurfInfo,
    activityWheelSurfDetail,
    activityWheelSurfList,
    activityWheelSurfOpen,
    activityWheelSurfProductList,
    activityWheelSurfSave,
    activityWheelSurfPopupList,

    communityGroupSettingInit,
    communityGroupSettingSave,
    communityGroupData,

    memberSignRecordList,
    signConfigEdit,
    signConfigModel,
    ordersupportIndex,
    afterSaleCount,
    ordersupportExchangeIndex,
    afterSaleExchangeCount,
    afterSaleAllRefundList,
    afterSaleList,
    afterSaleCount2,
    afterSaleAllRefundListExport,
    ordersupportExchangeIndexExport,
    afterSaleListExport,
    ordersupportIndexExport,

    ordersupportExport,
    ordersupportInit,
    ordersupportDetail,
    ordersupportExpress,
    ordersupportAgreeapply,
    ordersupportAgreerefund,
    ordersupportRefuseapply,
    ordersupportRefuserefund,
    ordersupportCloseApply,
    orderExport,
    orderExportRecordIndex,

    homeNavigationIndex,
    homeNavigationSave,
    mallShopDecorateSelect,
    mallShopDecorateSelectGroup,
    commentBatchCheck,
    commentDelete,
    commentList,
    commentMallReply,
    SignConfigIsOpen,
    SignConfigIsOpenState,
    dataStatisFlowOverview,
    dataStatisFlowReferer,
    dataStatisFlowRefererTrend,
    dataStatisFlowTrend,
    visitorVisitorAreaAnalyze,
    visitorVisitorDepth,
    dataStatisPageAnalyze,
    dataStatisCustomerOverview,
    dataStatisCustomerRetention,
    dataStatisTradingAnalyze,
    wxFansAnalyzeFanLayered,
    FansIncreaseOrDecrease,
    wxFansAnalyzeFansInfo,
    mallGetQrCode,
    dataStatisActivityDetail,
    dataStatisActivityGeneral,
    dataStatisActivityProduct,
    dataStatisCouponDetail,
    dataStatisCouponGeneral,
    dataStatisCouponProduct,
    activityDiscountPopupList,
    mallBalanceIndex,
    mallBalanceInit,
    verifyAuthGift,
    activityMardownGiftPopupList,
    performanceOrderList,
    performanceLockFansList,
    productSubscribeList,
    productSpecGet,
    expressAssistantIndex,
    expressAssistantSave,
    assistantRecordIndex,
    assistantRecordSend,
    assistantRecordStop,
    promoteCodeDelete,
    promoteCodeList,
    promoteCodeSave,
    mallShopDecorateBoxList,
    supplierMarketProductList,
    supplierMarketProductSave,
    supplierOrderInit,
    supplierOrderIndex,
    supplierOrderDetail,
    supplierOrderExpress,
    supplierOrderAgreeRefund,
    supplierOrderAgreeApply,
    supplierOrderRefuseApply,
    supplierOrderRefuseRefund,
    shopTagList,
    grossProfitList,
    setEmployeeCommunityGroupId,
    activitycouponActivitycouponupdate,
	deliverysConfirm,
	deliveryAddressList,
	deliveryShouhuo,
	userAddressList,
	createorderdetail,
	createorder,
	useraddressadd,
	vipcreateorder,
	vipfindOrderNo,
	activityproductmicrolist,
}