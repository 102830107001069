<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="优惠券名称"></el-input>
			</div>

      <div class="filter-item">
        <label class="label">优惠券类型: </label>
        <el-select v-model="type" placeholder="请选择">
          <el-option label="全部" :value="null"></el-option>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table height="450" :data="tableDataList" v-loading="loading" style="width: 100%" ref="compSelectProTable" @select-all='selectAll' :row-key="getRowKeys"
			@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"  :reserve-selection="true">
				</el-table-column>
				<el-table-column prop="couponName" label="优惠券" width="300"></el-table-column>
				<el-table-column prop="useTypeValue" label="类型"></el-table-column>
				<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
				<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
        <el-table-column prop="num" label="限领次数"></el-table-column>
				<el-table-column prop="leaveCount" label="剩余数量"></el-table-column>
			</el-table>

			<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="page.size"
			layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>

	// import config from '@/config/index'
	import otherApiList from '@/api/other.js'
	export default {
		name: 'selectCoupon',
		props: {
			api: String, //弹框商品列表请求接口名称, 统一加在 api/onther.js里
			selectedData: { //需要选中的商品列表
				type: Array,
				default:()=>{
					return []
				}
			},
			couponData:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			params:{ //自定义请求参数
				type: Array,
				default:()=>{
					return []
				}
			},
		},
		data() {
			return {
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '满减券'
					},
					{
						value: 2,
						label: '折扣券'
					},
					{
						value: 1,
						label: '随机金额券'
					}
				],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selectCount: 0,
				multipleSelection: [],
				
				loading: false
			}
		},
		created() {
			this.getSingleList()
		},
		watch:{
		},
		computed:{
			checkedAll(){
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var remarkLen = 0;
				var ids = val.map(item=>{
					return item.Id
				})
				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.Id) > -1 || item.Remark) {
						ableSelectLength ++;
					}
					if (item.Remark){
						remarkLen ++;
					}
				});
				
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength && remarkLen!=ableSelectLength) ? true:false;
			}
		},
		mounted() {
			
			
		},
		methods: {
			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						keyWords: this.searchKey,
						type: this.type,
						cardType: null,
						pageNo: this.page.current,
						pageSize: this.page.size,
					}
					let result = await otherApiList[this.api](data);
					this.page.total = result.data.total;
					this.tableDataList=result.data.records;
					this.tableDataList.map(item=>{
						item.type = item.type ? +item.type : 0;
						item.useTypeValue = item.type==0 ? '满减券' : (item.type==1 ? '随机金额券' : '折扣券');
						item.useNum = item.useNum ? item.useNum : 0;
						item.leaveCount = (+item.num) - (+item.useNum);
						return item;
					})

				} catch (error) {
					console.log(error);
				} finally {
					if (!this.isReady){
						this.selectedData.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false;
				}
			},
			//table原生全选按钮是否选中
			selectAll() {
			},
			//外部全选当前页
			selectCurrentAll() {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {

				this.multipleSelection = val;

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getSingleList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getSingleList()
			},
			// selectInit(row, id) {
			// 	return (row.Remark && row.Remark != '') ? false:true
			// },
			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectProTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.id;
			},
			handleFilter() {
				this.page.current = 1;
				this.getSingleList();
			},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if (!this.multipleSelection.length){
					this.$message({
						message: '请选择优惠券',
						type: 'info'
					})
					return
				}
				this.$emit('getSelectList', this.multipleSelection)
				//false，关闭弹框
				//this.multipleSelection:已选择优惠券
				console.log('this.multipleSelection', this.multipleSelection)
			}

		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}
	.dialog-name-content{
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    img{
      width:60px;
      height:60px;
      object-fit:cover;
      margin-right: 10px;
    }
  }
</style>
